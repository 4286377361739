import React, { useMemo } from "react";

import type { Meta } from "@/__main__/router.mjs";
import { GAME_NAME_MAP, GAME_SHORT_NAMES } from "@/app/constants.mjs";
import RelatedArticles from "@/marketing/RelatedArticles.jsx";
import Container from "@/shared/ContentContainer.jsx";
import { CONTENTFUL_TAGS } from "@/shared/contentful-constants.mjs";
import { findGameSymbol, useGameSymbol } from "@/util/game-route.mjs";

export default function Articles() {
  const gameSymbol = useGameSymbol();
  const gameTag = useMemo(
    () => CONTENTFUL_TAGS[GAME_SHORT_NAMES[gameSymbol]],
    [gameSymbol],
  );
  return (
    <Container>
      <RelatedArticles tags={[gameTag]} />
    </Container>
  );
}

export function meta(): Meta {
  const gameSymbol = findGameSymbol();
  const gameName = GAME_NAME_MAP[gameSymbol];
  return {
    title: [
      "contentful:articles.meta.title",
      "Articles Library - {{gameName}} - Blitz",
      { gameName },
    ],
    description: [
      "probuildsnet:articles.meta.description",
      "Browse our extensive article library & smart search tool. Discover diverse insights, expert opinions, and stories on any topic.",
    ],
  };
}
